import React, { useState, useRef } from "react";
import axios from "axios"; // 用于发送文件上传请求
import "./App.css"; // 用于定义样式

const params = new URLSearchParams(window.location.search);
const channel_id = params.get("channel_id");
const guild_id = params.get("guild_id");

// 定义一个文件上传组件
function FileUpload() {
  // 定义一些状态变量
  const [file, setFile] = useState(null); // 选择的文件
  const [uploading, setUploading] = useState(false); // 是否正在上传
  const [progress, setProgress] = useState(0); // 上传进度
  const [error, setError] = useState(null); // 上传错误信息
  const [cancelToken, setCancelToken] = useState(null); // 用于取消上传的令牌

  // 定义一些引用变量
  const fileInputRef = useRef(); // 文件输入框的引用
  const dropRef = useRef(); // 拖拽区域的引用

  // 定义一些常量
  const API_BASE_URL = "https://kookapi.tob.ee"; // 文件上传接口的URL
  const OSS_BASE_URL = "oss-cn-beijing.tob.ee";
  const HIGHLIGHT_STYLE = "2px dashed green"; // 高亮样式
  const NORMAL_STYLE = "2px dashed gray"; // 正常样式

  // 定义一个函数，用于处理文件选择事件
  const handleFileSelect = (e) => {
    e.preventDefault(); // 阻止默认行为
    let selectedFile = null; // 初始化选择的文件为null
    if (e.dataTransfer) {
      // 如果是拖拽事件，从dataTransfer中获取文件
      selectedFile = e.dataTransfer.files[0];
    } else {
      // 如果是点击事件，从target中获取文件
      selectedFile = e.target.files[0];
    }
    setFile(selectedFile); // 更新状态变量file
    if (selectedFile) {
      // 如果选择了文件，开始上传
      uploadFile(selectedFile);
    }
  };

  // 定义一个函数，用于处理文件上传事件
  const uploadFile = (file) => {
    console.log(file);
    setUploading(true); // 更新状态变量uploading为true，表示正在上传
    setError(null); // 更新状态变量error为null，表示没有错误信息
    setProgress(0); // 更新状态变量progress为0，表示初始进度为0%
    // const formData = new FormData(); // 创建一个表单数据对象
    // formData.append("file", file); // 将文件添加到表单数据中
    // const source = axios.CancelToken.source(); // 创建一个取消令牌源
    // setCancelToken(source); // 更新状态变量cancelToken为source，用于后续取消操作

    axios
      .post(API_BASE_URL + "/api/v3/asset/request-upload", {
        upload_count: 1,
        channel_id: "5643073756125298",
        guild_id: "3542195235225538"
      })
      .then((uploadRequest) => {
        console.log("request-upload success:", uploadRequest.data); // 打印响应数据
        if (uploadRequest.data.code !== 0) {
          throw new Error(uploadRequest.data.message);
        }
        const { access_token, upload_paths, bucket } = uploadRequest.data.data;

        const OSS = require("ali-oss");
        const client = new OSS({
          endpoint: OSS_BASE_URL,
          accessKeyId: access_token.access_key_id,
          accessKeySecret: access_token.access_key_secret,
          bucket: bucket,
          stsToken: access_token.security_token
        });

        // multipartUpload
        client
          .multipartUpload(upload_paths[0], file, {
            partSize: 1048576,
            parallel: 4,
            disabledMD5: true,
            mime: null,
            headers: {},
            progress: (p, cpt, res) => {
              // 获取上传进度
              setProgress(p * 100);
            }
          })
          .then((uploadRes) => {
            console.log("multipartUploadres success:", uploadRes);

            // 创建一个FormData对象，用于存储文件和其他参数
            const formData = new FormData();
            formData.append("oss", uploadRes.name);
            formData.append("filename", file.name);
            formData.append("channel_id", "5643073756125298");
            formData.append("guild_id", "3542195235225538");

            axios
              .post(API_BASE_URL + "/api/v3/asset/create", formData)
              .then((createRes) => {
                if (createRes.data.code !== 0) {
                  throw new Error(createRes.data.message);
                }
                // 处理响应数据
                forwardMessage(file.name, createRes.data.data.url, true);
              });
          });
      })
      .catch((err) => {
        console.log(err.message); // 打印错误信息
        if (!axios.isCancel(err)) {
          // 如果不是取消操作导致的错误，更新状态变量error为错误信息，并更新状态变量uploading为false，表示上传失败
          setError(err.message);
          setUploading(false);
          setFile(null);
        }
      });
    // axios
    //   .post(BASE_URL + "/api/v3/asset/create", formData, {
    //     headers: {
    //       "Content-Type": "multipart/form-data"
    //     },
    //     cancelToken: source.token, // 设置取消令牌
    //     onUploadProgress: (e) => {
    //       // 设置上传进度回调函数，根据已上传和总大小计算百分比，并更新状态变量progress
    //       const percent = Math.round((e.loaded * 100) / e.total);
    //       setProgress(percent);
    //     }
    //   })
    //   .then((res) => {
    //     console.log(res.data); // 打印响应数据
    //     forwardMessage(res.data.data.url);
    //     // handleUploadSuccess();
    //   })
    //   .catch((err) => {
    //     console.log(err.message); // 打印错误信息
    //     if (!axios.isCancel(err)) {
    //       // 如果不是取消操作导致的错误，更新状态变量error为错误信息，并更新状态变量uploading为false，表示上传失败
    //       setError(err.message);
    //       setUploading(false);
    //       setFile(null);
    //     }
    //   });
  };

  const forwardMessage = (fileName, fileUrl, isVideo) => {
    const videoContent = {
      type: "video",
      cover: `${fileUrl}?x-oss-process=video/snapshot,t_4000,f_jpg,m_fast`,
      title: `${fileName}`,
      src: `${fileUrl}`,
      external: false,
      canDownload: true,
      elements: []
    };

    const downloadContent = {
      type: "file",
      title: `${fileName}`,
      src: `${fileUrl}`
    };

    // 设置请求参数
    const body = {
      type: 10,
      target_id: channel_id, // 目标频道id
      content: JSON.stringify([
        {
          theme: "secondary",
          color: "",
          size: "lg",
          expand: false,
          modules: [isVideo ? videoContent : downloadContent],
          type: "card"
        }
      ])
    };
    axios
      .post(API_BASE_URL + "/api/v3/message/create", body)
      .then((res) => {
        console.log(res.data); // 打印响应数据
        if (res.data.code !== 0) {
          if (isVideo) {
            return forwardMessage(fileName, fileUrl, false);
          } else {
            throw new Error(res.data.message);
          }
        }
        setUploading(false); // 更新状态变量uploading为false，表示上传完成
        setFile(null); // 更新状态变量file为null，表示没有选择的文件
      })
      .catch((err) => {
        console.log(err.message); // 打印错误信息
        if (!axios.isCancel(err)) {
          // 如果不是取消操作导致的错误，更新状态变量error为错误信息，并更新状态变量uploading为false，表示上传失败
          setError(err.message);
          setUploading(false);
          setFile(null);
        }
      });
  };

  // 定义一个函数，用于处理取消上传事件
  const handleCancelUpload = () => {
    if (cancelToken) {
      // 如果有取消令牌，调用其cancel方法，并传入一个消息参数
      cancelToken.cancel("Upload cancelled by user.");
      setUploading(false); // 更新状态变量uploading为false，表示上传完成
      setFile(null); // 更新状态变量file为null，表示没有选择的文件
    }
  };

  // 定义一个函数，用于处理文件上传成功事件
  const handleUploadSuccess = () => {
    // dropRef.current.classList.add("upload-done"); // 给拖拽区域添加upload-done类名，用于执行reverse动画
    // setTimeout(() => {
    //   // 设置一个定时器，在动画结束后，移除upload-done类名，恢复正常状态
    //   dropRef.current.classList.remove("upload-done");
    // }, 500);
  };

  // 定义一个函数，用于处理拖拽进入事件
  const handleDragEnter = (e) => {
    e.preventDefault(); // 阻止默认行为
    e.stopPropagation(); // 阻止事件冒泡
    if (!uploading) {
      // 如果没有正在上传，将拖拽区域的边框样式设置为高亮样式
      dropRef.current.style.border = HIGHLIGHT_STYLE;
    }
  };

  // 定义一个函数，用于处理拖拽离开事件
  const handleDragLeave = (e) => {
    e.preventDefault(); // 阻止默认行为
    e.stopPropagation(); // 阻止事件冒泡
    if (!uploading) {
      // 如果没有正在上传，将拖拽区域的边框样式设置为正常样式
      dropRef.current.style.border = NORMAL_STYLE;
    }
  };

  // 定义一个函数，用于处理拖拽结束事件
  const handleDragOver = (e) => {
    e.preventDefault(); // 阻止默认行为
    e.stopPropagation(); // 阻止事件冒泡
  };

  // 定义一个函数，用于处理拖拽开始事件
  const handleDragStart = (e) => {
    e.preventDefault(); // 阻止默认行为
    e.stopPropagation(); // 阻止事件冒泡
    if (!uploading) {
      // 如果没有正在上传，将拖拽区域的边框样式设置为高亮样式
      dropRef.current.style.border = HIGHLIGHT_STYLE;
    }
  };

  // 定义一个函数，用于处理拖拽结束事件
  const handleDragEnd = (e) => {
    e.preventDefault(); // 阻止默认行为
    e.stopPropagation(); // 阻止事件冒泡
    if (!uploading) {
      // 如果没有正在上传，将拖拽区域的边框样式设置为正常样式
      dropRef.current.style.border = NORMAL_STYLE;
    }
  };

  // 定义一个函数，用于处理点击事件
  const handleClick = () => {
    if (!uploading) {
      // 如果没有正在上传，触发文件输入框的点击事件，打开文件选择对话框
      fileInputRef.current.click();
    }
  };

  // 返回JSX元素，渲染文件上传组件的界面
  return (
    <div className="container">
      <h1>KOOK Video Share</h1>
      {uploading ? (
        <div className="progress-area">
          <div className="progress-bar" style={{ width: `${progress}%` }}>
            {progress}%
          </div>
          {/* <button className="close-button" onClick={handleCancelUpload}>
            X
          </button> */}
        </div>
      ) : (
        <div
          className="drop-area"
          ref={dropRef}
          onClick={handleClick}
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
          onDragOver={handleDragOver}
          onDrop={handleFileSelect}
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
        >
          <div className="upload-area">
            <p>点击或拖拽文件至此处上传</p>
            <p>click or drag and drop the file to upload here</p>
            <p>
              ここにファイルをクリックまたはドラッグアンドドロップしてアップロードする
            </p>
          </div>
          <input
            type="file"
            ref={fileInputRef}
            onChange={handleFileSelect}
            style={{ display: "none" }}
          />
        </div>
      )}
      {error && <p className="error-message">{error}</p>}
    </div>
  );
}

// 导出文件上传组件
export default FileUpload;
